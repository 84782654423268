import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { PageLinks } from "../../common/site/page-static-links"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./SectionViewerComponent.scss"
import { capitalize } from "../Common/utils"

const SectionViewerComponent = props => {
  const [active, setActive] = useState(0)

  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollResult, setScrollResult] = useState("")

  const { isTabletOnly, isMobile } = useDeviceMedia()

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const position = window.scrollY
        if (position > 630 && isTabletOnly) {
          setScrollResult("top-position")
        } else if (position > 537 && isMobile) {
          setScrollResult("top-position")
        } else {
          setScrollResult("")
        }
        setScrollPosition(position)
      }

      window.addEventListener("scroll", handleScroll)

      return () => window.removeEventListener("scroll", handleScroll)
    }
  }, [isTabletOnly, isMobile])

  let menus = []

  if (props.isCampaign) {
    props.idArray.filter(data => {
      if (data === "about-block") {
        menus.push({ label: "about", id: "about-block", key: "about_" })
      }
      if (data === "location-block") {
        menus.push({
          label: "location",
          id: "location-block",
          key: "location_",
        })
      }
      if (data === "gallery-block") {
        menus.push({ label: "gallery", id: "gallery-block", key: "gallery_" })
      }
      if (data === "payment-block") {
        menus.push({
          label: "payment plan",
          id: "payment-block",
          key: "payment_",
        })
      }
    })
  }

  if (props?.isEvent) {
    const removeDuplicate = props?.idArray?.filter((arr, index) => {
      return props.idArray.indexOf(arr) === index
    })

    removeDuplicate?.filter(data => {
      if (data === "overview") {
        menus.push({ label: "overview", id: "overview" })
      }
      if (data === "why-attend") {
        menus.push({ label: "why attend", id: "why-attend" })
      }
      //   if(data==="location-block"){
      //     menus.push({label:"location",id:"location-block"});
      //  }
      if (data === "speakers") {
        menus.push({ label: "speakers", id: "speakers" })
      }
      if (data === "about") {
        menus.push({ label: "about", id: "about" })
      }
      if (data === "agenda") {
        menus.push({ label: "agenda", id: "agenda" })
      }
    })
  }

  const handleLink = (id, index, key) => {
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth", block: "center" })
    if (active === index) {
      return setActive(null)
    }
    setActive(index)
  }


  const handleScrolling = () => {
    const navLinks=menus?.slice(0);
    const sectionEl = document.querySelectorAll(".section_item");
    let currentSection =props?.isCampaign?"about-block":"overview"
    if (isMobile||isTabletOnly) {
      sectionEl.forEach(ele => {
        if (window.scrollY >= parseInt(ele.offsetTop)-100) {
          currentSection = ele.id
        }
      })

      navLinks.forEach((link,index)=>{
       if(currentSection){
        if(link?.id?.includes(currentSection)){
            setActive(index)
        }
      }
      })
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScrolling)

  }, [isTabletOnly, isMobile])



  return (
    <div className={`component-scroll-wrapper ${scrollResult}`} id="scroll-cmp">
      {props?.strapiId!==8&&
      <Container>
        <div
          className={`scroll-to-component ${
            props?.isCampaign ? "campaign" : "event"
          }`}
        >
          {menus &&
            menus.map((menu, index) => {
              return (
                <div
                  className={`list-item ${active === index ? "active" : ""}`}
                >
                  <a onClick={() => handleLink(menu?.id, index)}>{menu?.label ? capitalize(menu.label):menu.label}</a>
                </div>
              )
            })}
             {props?.isCampaign &&
                <div className={`list-item`}>
                      <Link to={`/${PageLinks?.career}/`}>{PageLinks?.career_label}</Link>
                    </div>}
        </div>
      </Container>
      }
    </div>
  )
}

export default SectionViewerComponent
